<template>
  <div>
    <v-radio-group
      v-model="instanceId"
    >
      <v-radio
        v-for="instanceItem in instances"
        :key="instanceItem.id"
        :label="getLabel(instanceItem)"
        :value="instanceItem.id"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    popupConfig: Object,
  },
  data: () => ({
    instanceId: null,
  }),
  methods: {
    getLabel(instanceItem) {
      const name = instanceItem.name
      const price = this.$helper.amount(instanceItem.price)
      return `${name} / ${price}`
    },
  },
  computed: {
    instances() {
      if(!this.popupConfig) return null
      if(!this.popupConfig.product) return null
      if(!Array.isArray(this.popupConfig.product.instances)) return null
      return this.popupConfig.product.instances
    },
    instance() {
      if(!this.instances) return
      if(!this.instanceId) return
      return this.instances.find(instance => instance.id === this.instanceId)
    },
  },
  watch: {
    instance: {
      deep: true,
      handler() {
        this.$emit('updateData', window.eagleLodash.cloneDeep(this.instance))
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
